import React, { useEffect } from "react";
import styles from "./Sidebar.module.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { SidebarItem } from "./components/SidebarItem";
import { RoutePaths } from "../../pages/routePaths";
import { addExtension } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

export const Sidebar = () => {
  const { pathname } = useLocation();
  const push = useNavigate();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { user } = userDetail;
  const { subscribedProduct } = useSelector((state) => state.paymentReducer);
  const [searchData, setSearchData] = useState(0);

  useEffect(() => {
    remainingSearches();
  }, [subscribedProduct, user]);

  const remainingSearches = async () => {
    const userDataJSON = localStorage.getItem("PS_USER");
    const userData = JSON.parse(userDataJSON);
    const access_token = userData?.userDetail?.access_token;
    console.log("USER DATA", userData?.userDetail?.access_token);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      const searches = await axios.get(
        "https://api.poundsqueeze.co.uk/api/v1/subscription/rs",
        config
      );
      setSearchData(searches.data.remainingSearches);
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url =
      "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    const newWindow = window.open(
      url,
      "_blank",
      "width=800,height=1000,resizable=yes,noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <nav className={styles.container}>
      <SidebarItem
        path={RoutePaths.Profile}
        isSelected={pathname === RoutePaths.Profile}
      >
        Profile
      </SidebarItem>
      {/* <SidebarItem
        path={RoutePaths.Billing}
        isSelected={pathname === RoutePaths.Billing}
      >
        Buy Searches
      </SidebarItem>
      <SidebarItem
        path={RoutePaths.Referrals}
        isSelected={pathname === RoutePaths.Referrals}
      >
        Free Searches
      </SidebarItem> */}
      <SidebarItem
        path={RoutePaths.Support}
        isSelected={pathname === RoutePaths.Support}
      >
        Support
      </SidebarItem>
      <SidebarItem
        path={RoutePaths.HowToUse}
        isSelected={pathname === RoutePaths.HowToUse}
      >
        How to use?
      </SidebarItem>
      <div className={styles.creditContainer}>
        <div className={styles.creditCircle}>
          <span className={styles.creditText}>
            <span style={{ fontSize: "40px" }}>
              <b>{/* {searchData} */}∞</b>
            </span>
            <br />
            <p>searches left</p>{" "}
          </span>
        </div>
      </div>
      {/* <button onClick={()=>push('/billing')} className={styles.creditButton}>Get more searches</button> */}
    </nav>
  );
};
